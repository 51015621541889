'use strict';

import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';



jQuery(function () {
    //Set Slick Nav Classes
    // $('.home-service-container-slick').on('init', function (event, slick, currentSlide, nextSlide) {
    //     if ($(slick.$slides[slick.$slides.length - 1]).hasClass('slick-active')) {
    //         $('.slick-nav-right').addClass('inactive');
    //     } else {
    //         $('.slick-nav-right').removeClass('inactive');
    //     }


    //     if ($(slick.$slides[0]).hasClass('slick-active')) {
    //         $('.slick-nav-left').addClass('inactive');
    //     } else {
    //         $('.slick-nav-left').removeClass('inactive');
    //     }
    // });

    // $('.home-service-container-slick').on('afterChange', function (event, slick, currentSlide, nextSlide) {
    //     if ($(slick.$slides[slick.$slides.length - 1]).hasClass('slick-active')) {
    //         $('.slick-nav-right').addClass('inactive');
    //     } else {
    //         $('.slick-nav-right').removeClass('inactive');
    //     }


    //     if ($(slick.$slides[0]).hasClass('slick-active')) {
    //         $('.slick-nav-left').addClass('inactive');
    //     } else {
    //         $('.slick-nav-left').removeClass('inactive');
    //     }
    // });

    //Init Slick
    $('#hgm-home-slider-slides').slick({
        infinite: true,
        autoplay: false,
        autoplaySpeed: 10000,
        variableWidth: true,
        dots: true,
        arrows: false
    });

    // $('#hgm-home-slider-slides').on('afterChange', function(event, slick, nextSlide){
    //     const len = slick.slideCount;
    //     let next = nextSlide + 1;
    //     if(next >= len){
    //         next = 0;
    //     }
    //     console.log(next)
    //     const slickNextSlide = $(slick.$slides.get(next));
    //     console.log(slick)
    //     $('#hgm-home-slider-slides').find('.slide').removeClass('active');
    //     slickNextSlide.find('.slide').addClass('active');
    // })

    //Slick Nav
    $('.slick-nav-right').on('click', function (e) {
        e.preventDefault();
        $('#hgm-home-slider-slides').slick('slickNext');
    })

});